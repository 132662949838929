

.card_client {
  display: flex;
  direction: row;
  align-items: center;
  width: 90%;
  height: 216px;
  flex-shrink: 0;
  border: 2px solid #ff9921;
  background: #f5f5f5;
  margin-left: 4% !important;
  margin-top: 5%;
}
.ellipse_image {
  width: 106px;
  height: 106px;
  flex-shrink: 0;
  border-radius: 106px;
  margin-top: 0%;
  margin-left: 30%;
}
.client_name {
  color: #2c2c2c;
  text-align: start;
  font-family: "Inter";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10%;
  margin-left: 30%;
}
.paragraf_client {
  width: 300px;
  color: #000;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 35%;
  margin-top: 6%;
}

@media only screen and ( max-width: 1400px ){
  .card_client {
    display: flex;
    direction: row;
    align-items: center;
    width: 90%;
    height: 200px;
    flex-shrink: 0;
    border: 2px solid #ff9921;
    background: #f5f5f5;
    margin-left: 4% !important;
    margin-top: 5%;
  }
  .ellipse_image {
    width: 106px;
    height: 106px;
    flex-shrink: 0;
    border-radius: 106px;
    margin-top: 0%;
    margin-left: 30%;
  }
  .client_name {
    color: #2c2c2c;
    text-align: start;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10%;
    margin-left: 30%;
  }
  .paragraf_client {
    width: 300px;
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 15%;
    margin-top: 6%;
  }
}

@media only screen and ( max-width: 1200px ){
  .card_client {
    display: flex;
    direction: row;
    align-items: center;
    width: 90%;
    height: 200px;
    flex-shrink: 0;
    border: 2px solid #ff9921;
    background: #f5f5f5;
    margin-left: 4% !important;
    margin-top: 5%;
  }
  .ellipse_image {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 106px;
    margin-top: 0%;
    margin-left: 20%;
  }
  .client_name {
    color: #2c2c2c;
    text-align: start;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10%;
    margin-left: 20%;
  }
  .paragraf_client {
    width: 300px;
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10%;
    margin-top: 3%;
    padding-right: 12% !important;
  }
}
@media only screen and ( max-width: 1000px ){
  .card_client {
    display: flex;
    direction: row;
    align-items: center;
    width: 94%;
    height: 180px;
    flex-shrink: 0;
    border: 2px solid #ff9921;
    background: #f5f5f5;
    margin-left: 4% !important;
    margin-top: 5%;
  }
  .ellipse_image {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 106px;
    margin-top: 0%;
    margin-left: 20%;
  }
  .client_name {
    color: #2c2c2c;
    text-align: start;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10%;
    margin-left: 25%;
  }
  .paragraf_client {
    width: 300px;
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10%;
    margin-top: 3%;
    padding-right: 38% !important;
  }
}
@media only screen and ( max-width: 770px ){
  .card_client {
    display: flex;
    direction: row;
    align-items: center;
    width: 94%;
    height: 180px;
    flex-shrink: 0;
    border: 2px solid #ff9921;
    background: #f5f5f5;
    margin-left: 4% !important;
    margin-top: 5%;
  }
  .ellipse_image {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 106px;
    margin-top: 0%;
    margin-left: 30%;
  }
  .client_name {
    color: #2c2c2c;
    text-align: start;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10%;
    margin-left: 30%;
  }
  .paragraf_client {
    width: 300px;
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 25%;
    margin-top: 3%;
    padding-right: 10% !important;
  }
}

@media only screen and ( max-width: 580px ){
 .container{
  width: 90%;
 }
  .card_client {
    display: flex;
    direction: row;
    align-items: center;
    width: 100%;
    height: 160px;
    flex-shrink: 0;
    border: 2px solid #ff9921;
    background: #f5f5f5;
    margin-left: 0% !important;
    margin-top: 5%;
  }
  .ellipse_image {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 106px;
    margin-top: 5%;
    margin-left: 10%;
  }
  .client_name {
    color: #2c2c2c;
    text-align: start;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10%;
    margin-left: 10%;
  }
  .paragraf_client {
    width: 250px;
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20%;
    margin-top: 3%;
    padding-right: 0% !important;
  }
}

@media only screen and ( max-width: 480px ){
  .container{
   width: 90%;
  }
   .card_client {
     display: flex;
     direction: row;
     align-items: center;
     width: 100%;
     height: 160px;
     flex-shrink: 0;
     border: 2px solid #ff9921;
     background: #f5f5f5;
     margin-left: 0% !important;
     margin-top: 5%;
   }
   .ellipse_image {
     width: 80px;
     height: 80px;
     flex-shrink: 0;
     border-radius: 106px;
     margin-top: 5%;
     margin-left: 10%;
   }
   .client_name {
     color: #2c2c2c;
     text-align: start;
     font-family: "Inter";
     font-size: 20px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     margin-top: 10%;
     margin-left: 10%;
   }
   .paragraf_client {
     width: 250px;
     color: #000;
     text-align: center;
     font-family: "Inter";
     font-size: 13px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
     margin-left: 10%;
     margin-top: 3%;
     padding-right: 20% !important;
   }
 }

 @media only screen and ( max-width: 400px ){
  .container{
   width: 90%;
  }
   .card_client {
     display: flex;
     direction: row;
     align-items: center;
     width: 100%;
     height: 130px;
     flex-shrink: 0;
     border: 2px solid #ff9921;
     background: #f5f5f5;
     margin-left: 0% !important;
     margin-top: 5%;
   }
   .ellipse_image {
     width: 60px;
     height: 60px;
     flex-shrink: 0;
     border-radius: 106px;
     margin-top: 5%;
     margin-left: 10%;
   }
   .client_name {
     color: #2c2c2c;
     text-align: start;
     font-family: "Inter";
     font-size: 16px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     margin-top: 10%;
     margin-left: 10%;
   }
   .paragraf_client {
     width: 240px;
     color: #000;
     text-align: center;
     font-family: "Inter";
     font-size: 12px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
     margin-left: 10%;
     margin-top: 3%;
     padding-right: 30% !important;
   }
 }

 @media only screen and ( max-width: 345px ){
  .container{
   width: 90%;
  }
   .card_client {
     display: flex;
     direction: row;
     align-items: center;
     width: 100%;
     height: 110px;
     flex-shrink: 0;
     border: 2px solid #ff9921;
     background: #f5f5f5;
     margin-left: 0% !important;
     margin-top: 5%;
   }
   .ellipse_image {
     width: 60px;
     height: 60px;
     flex-shrink: 0;
     border-radius: 106px;
     margin-top: 5%;
     margin-left: 10%;
   }
   .client_name {
     color: #2c2c2c;
     text-align: start;
     font-family: "Inter";
     font-size: 16px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     margin-top: 10%;
     margin-left: 10%;
   }
   .paragraf_client {
     width: 240px;
     color: #000;
     text-align: center;
     font-family: "Inter";
     font-size: 11px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
     margin-left: 10%;
     margin-top: 3%;
     padding-right: 40% !important;
   }
 }