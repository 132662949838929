.main_div3{
  width: 100%;
  height: 800px;
}

.background_img_sellyourhouse {
  background-image: url("../../Assets/background-img-template15.png");
  width: 100%;
  height: 635px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  padding: 2%;
  
}
.heading_house {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
  font-family: "Inter";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 0%;
}
.paragraf_house {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 1%;
  padding-right: 22%;
  padding-left: 22%;
}
.helped_heading {
  color: #ff9921;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 2%;
}

.points_box{
    width: 33px;
height: 32px;
flex-shrink: 0;
border-radius: 69px;
background: #FF9921;
margin-top: 8%;
}
.inputs_name_sellhouse{
    color: #FFF;
    width: 180px;
    text-align: start;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 130%;
    padding-top: 10%;
}
.fill_out_input{
  color: #FFF;
text-align: center;
text-shadow: 0px 4px 1px #000;
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 2%;
}
.parsonal_information_section{
  width: 65%;
height: 270px;
flex-shrink: 0;
border-radius: 20px;
background: #FF9921;
box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
margin-top: 1% ;
margin-left: 18% ;
}
.property_address{
  color: #FFF;
text-align: start;
font-family: "Inter";
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 3%;
padding-top: 2%;
}
.property_input{
  width: 96%;
height: 42px;
flex-shrink: 0;
border-radius: 5px;
background: #F8F8F8;
margin-left: 0%;
padding-right: 2%;
border-style: none;
}


.property_name{
  color: #FFF;
text-align: start;
font-family: "Inter";
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 14%;
}

.name_input{
  width: 100%;
height: 42px;
flex-shrink: 0;
border-radius: 5px;
background: #F8F8F8;

border-style: none;
}
.property_email{
  color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5%;
}
.email_input{
  width: 92%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  border-style: none;
  
}
.get_cash_button{
  width: 45%;
height: 40px;
flex-shrink: 0;
border-radius: 5px;
background: #9ED600;
box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
margin-top: 3%;
margin-left:26% ;
}

.get_my_fair{
  color: #2C2C2C;
text-align: center;
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 2%;
}

@media only screen and (max-width: 2500px) {
  .main_div3{
    width: 100%;
    height: 800px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 635px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 1%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 22%;
    padding-left: 22%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 1%;
  }
  
  .points_box{
      width: 33px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 180px;
      text-align: start;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 130%;
      padding-top: 10%;
      
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 270px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 1% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
 
  }
  .property_input{
    width: 96%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  padding-left: 1%;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  padding-left: 5%;
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    padding-left: 3%;
  }
  .get_cash_button{
    width: 45%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 2%;
  }
}

@media only screen and (max-width: 2200px) {
  .main_div3{
    width: 100%;
    height: 800px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 635px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 2%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 22%;
    padding-left: 22%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 2%;
  }
  
  .points_box{
      width: 33px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 180px;
      text-align: start;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 130%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 270px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 1% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
  }
  .property_input{
    width: 96%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 2%;
  }
}

@media only screen and (max-width: 1800px) {
  .main_div3{
    width: 100%;
    height: 800px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 635px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 3%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 22%;
    padding-left: 22%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 3%;
  }
  
  .points_box{
      width: 33px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 180px;
      text-align: start;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 130%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 270px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 1% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
  }
  .property_input{
    width: 96%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 2%;
  }
}

@media only screen and (max-width: 1400px) {
  .main_div3{
    width: 100%;
    height: 800px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 635px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 3%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 22%;
    padding-left: 22%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
  
  .points_box{
      width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 160px;
      text-align: start;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 130%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 270px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 1% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
  }
  .property_input{
    width: 96%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 2%;
  }
}

@media only screen and (max-width: 1200px) {
  .main_div3{
    width: 100%;
    height: 800px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 635px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 4%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 22%;
    padding-left: 22%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
  
  .points_box{
      width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 130px;
      text-align: start;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 130%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 250px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 3% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
  }
  .property_input{
    width: 96%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 2%;
  }
}

@media only screen and (max-width: 1000px) {
  .main_div3{
    width: 100%;
    height: 800%;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 600px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 4%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 22%;
    padding-left: 22%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
  
  .points_box{
      width: 25px;
  height: 25px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 120px;
      text-align: start;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 130%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 8%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 250px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 3% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
  }
  .property_input{
    width: 96%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 4%;
  }
}
@media only screen and (max-width: 800px) {
  .main_div3{
    width: 100%;
    height: 620px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 470px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 4%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 22%;
    padding-left: 22%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
  
  .points_box{
      width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 110px;
      text-align: start;
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 110%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 200px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 4% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
  }
  .property_input{
    width: 96%;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 34px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 4%;
  }
}

@media only screen and (max-width: 630px) {
  .main_div3{
    width: 100%;
    height: 520px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 390px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 4%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 12%;
    padding-left: 12%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
  
  .points_box{
      width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 90px;
      text-align: start;
      font-family: "Inter";
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 110%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 160px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 4% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
  }
  .property_input{
    width: 96%;
  height: 28px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 28px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 28px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 28px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 6%;
  }
}

@media only screen and (max-width: 510px) {
  .main_div3{
    width: 100%;
    height: 400px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 340px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 2%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 12%;
    padding-left: 12%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 3%;
  }
  
  .points_box{
      width: 14px;
  height: 14px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 8%;
  align-items: start;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 70px;
      text-align: start;
      font-family: "Inter";
      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 110%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 140px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 4% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 2%;
  }
  .property_input{
    width: 96%;
  height: 24px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 100%;
  height: 24px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 24px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 24px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 6%;
  }
}

@media only screen and (max-width: 420px) {
  .main_div3{
    width: 100%;
    height: 300px;
  }
  
  .background_img_sellyourhouse {
    background-image: url("../../Assets/background-img-template15.png");
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 2%;
    
  }
  .heading_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.71);
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
  .paragraf_house {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1%;
    padding-right: 12%;
    padding-left: 12%;
  }
  .helped_heading {
    color: #ff9921;
    text-align: center;
    text-shadow: 0px 4px 1px #000;
    font-family: "Inter";
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 3%;
  }
  
  .points_box{
      width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 69px;
  background: #FF9921;
  margin-top: 6%;
  align-items: start;
  }
  .inputs_name_sellhouse{
      color: #FFF;
      width: 50px;
      text-align: start;
      font-family: "Inter";
      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 110%;
      padding-top: 10%;
  }
  .fill_out_input{
    color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 1px #000;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
  }
  .parsonal_information_section{
    width: 65%;
  height: 120px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FF9921;
  box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 4% ;
  margin-left: 18% ;
  }
  .property_address{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3%;
  padding-top: 3%;
  }
  .property_input{
    width: 96%;
  height: 20px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 0%;
  padding-right: 2%;
  border-style: none;
  }
  
  
  .property_name{
    color: #FFF;
  text-align: start;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14%;
  }
  
  .name_input{
    width: 150%;
  height: 20px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  
  border-style: none;
  }
  .property_email{
    color: #FFF;
    text-align: start;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }
  .email_input{
    width: 92%;
    height: 20px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    border-style: none;
    
  }
  .get_cash_button{
    width: 45%;
  height: 20px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ED600;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.41);
  margin-top: 3%;
  margin-left:26% ;
  }
  
  .get_my_fair{
    color: #2C2C2C;
  text-align: center;
  font-family: "Inter";
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 6%;
  }
}