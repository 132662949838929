.section_style {
  background: #2c2c2c;
  width: 100%;
  height: 900px;
  background-size: cover;
}

.property_heading {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 3%;
}
.selling_heading {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.yellow_option {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #ff9921;
}
.input_proparty {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 2%;
}
.points_yellow {
  width: 30px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 67px;
  background: #ff9921;
  margin-left: 2%;
  margin-top: 3% !important;
}
.input_options {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border: 1px solid #2c2c2c;
  background: #d1d1d1;
}
.input_options1 {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border: 1px solid #000;
  background: #ffffff;
}
.input_box {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
}

.input_name1 {
  color: #000;
  text-align: center;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 2%;
}
.input_name {
  color: #2c2c2c;
  text-align: start;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 0%;
  padding-left: 150%;
  width: 400px;
}
.green_option {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9ed600;
}
.input_green {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 2%;
}
.blue_option {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #52acff;
}
.input_blue {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 2%;
}
.offer_button_box {
  width: 60%;
  height: 84px;
  border-radius: 10px;
  background: #ff9921;
  margin-top: 5%;
  margin-left: 20%;
  padding-bottom: 5%;
}
.input_offer_button {
  color: #2c2c2c;
  text-align: center;
  font-family: "Inter";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 3%;
}

@media only screen and (max-width: 1400px) {
  .section_style {
    background: #2c2c2c;
    width: 100%;
    /* height: 900px; */
    background-size: cover;
  }

  .property_heading {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 3%;
  }
  .selling_heading {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .yellow_option {
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #ff9921;
  }
  .input_proparty {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 5%;
  }
  .points_yellow {
    width: 30px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 67px;
    background: #ff9921;
    margin-left: 2%;
    margin-top: 3% !important;
  }
  .input_options {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border: 1px solid #2c2c2c;
    background: #d1d1d1;
  }
  .input_options1 {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border: 1px solid #000;
    background: #ffffff;
  }
  .input_box {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
  }

  .input_name1 {
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 2%;
  }
  .input_name {
    color: #2c2c2c;
    text-align: start;
    font-family: "Inter";
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 0%;
    padding-left: 120%;
    width: 300px;
  }
  .green_option {
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #9ed600;
  }
  .input_green {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 5%;
  }
  .blue_option {
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #52acff;
  }
  .input_blue {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 5%;
  }
  .offer_button_box {
    width: 60%;
    height: 84px;
    border-radius: 10px;
    background: #ff9921;
    margin-top: 5%;
    margin-left: 20%;
  }
  .input_offer_button {
    color: #2c2c2c;
    text-align: center;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
}

@media only screen and (max-width: 1200px) {
  .section_style {
    background: #2c2c2c;
    width: 100%;
    /* height: 900px; */
    background-size: cover;
  }

  .property_heading {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 3%;
  }
  .selling_heading {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .yellow_option {
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #ff9921;
  }
  .input_proparty {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 5%;
  }
  .points_yellow {
    width: 30px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 67px;
    background: #ff9921;
    margin-left: 2%;
    margin-top: 3% !important;
  }
  .input_options {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border: 1px solid #2c2c2c;
    background: #d1d1d1;
  }
  .input_options1 {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border: 1px solid #000;
    background: #ffffff;
  }
  .input_box {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
  }

  .input_name1 {
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 5%;
  }
  .input_name {
    color: #2c2c2c;
    text-align: start;
    font-family: "Inter";
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 0%;
    padding-left: 120%;
    width: 300px;
  }
  .green_option {
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #9ed600;
  }
  .input_green {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 5%;
  }
  .blue_option {
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #52acff;
  }
  .input_blue {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 5%;
  }
  .offer_button_box {
    width: 60%;
    height: 84px;
    border-radius: 10px;
    background: #ff9921;
    margin-top: 5%;
    margin-left: 20%;
  }
  .input_offer_button {
    color: #2c2c2c;
    text-align: center;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
  }
}


@media only screen and (max-width: 1000px) {
    .section_style {
      background: #2c2c2c;
      width: 100%;
      height: 800px;
      background-size: cover;
      margin-top: 20%;
    }
  
    .property_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 3%;
    }
    .selling_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .yellow_option {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #ff9921;
    }
    .input_proparty {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
    .points_yellow {
      width: 25px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 67px;
      background: #ff9921;
      margin-left: 2%;
      margin-top: 5% !important;
    }
    .input_options {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #2c2c2c;
      background: #d1d1d1;
    }
    .input_options1 {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #000;
      background: #ffffff;
    }
    .input_box {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
    }
  
    .input_name1 {
      color: #000;
      text-align: center;
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-top: 8%;
    }
    .input_name {
      color: #2c2c2c;
      text-align: start;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 10% !important;
      padding-left: 120%;
      width: 230px;
    }
    .green_option {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #9ed600;
    }
    .input_green {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
    .blue_option {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #52acff;
    }
    .input_blue {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
    .offer_button_box {
      width: 60%;
      height: 74px;
      border-radius: 10px;
      background: #ff9921;
      margin-top: 5%;
      margin-left: 20%;
    }
    .input_offer_button {
      color: #2c2c2c;
      text-align: center;
      font-family: "Inter";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
  }

  @media only screen and (max-width: 767px) {
    .section_style {
      background: #2c2c2c;
      width: 100%;
      height: 800px;
      background-size: cover;
      margin-top: 0%;
    }
  
    .property_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 3%;
    }
    .selling_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .yellow_option {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #ff9921;
    }
    .input_proparty {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
    .points_yellow {
      width: 25px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 67px;
      background: #ff9921;
      margin-left: 2%;
      margin-top: 5% !important;
    }
    .input_options {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #2c2c2c;
      background: #d1d1d1;
    }
    .input_options1 {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #000;
      background: #ffffff;
    }
    .input_box {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
    }
  
    .input_name1 {
      color: #000;
      text-align: center;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 2%;
    }
    .input_name {
      color: #2c2c2c;
      text-align: start;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 10% !important;
      padding-left: 120%;
      width: 230px;
    }
    .green_option {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #9ed600;
    }
    .input_green {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
    .blue_option {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #52acff;
    }
    .input_blue {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
    .offer_button_box {
      width: 60%;
      height: 74px;
      border-radius: 10px;
      background: #ff9921;
      margin-top: 5%;
      margin-left: 20%;
    }
    .input_offer_button {
      color: #2c2c2c;
      text-align: center;
      font-family: "Inter";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
  }

  @media only screen and (max-width: 767px) {
    .section_style {
      background: #2c2c2c;
      width: 100%;
      height: 1770px;
      background-size: cover;
      margin-top: 0%;
    }
  
    .property_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 3%;
    }
    .selling_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .yellow_option {
      width: 100%;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #ff9921;
    }
    .input_proparty {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
    }
    .points_yellow {
      width: 31px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 67px;
      background: #ff9921;
      margin-left: 2%;
      margin-top: 2% !important;
    }
    .input_options {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #2c2c2c;
      background: #d1d1d1;
    }
    .input_options1 {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #000;
      background: #ffffff;
    }
    .input_box {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
    }
  
    .input_name1 {
      color: #000;
      text-align: center;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 2%;
    }
    .input_name {
      color: #2c2c2c;
      text-align: start;
      font-family: "Inter";
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 0% !important;
      padding-left: 140%;
      width: 450px;
    }
    .green_option {
      width: 100%;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #9ed600;
    }
    .input_green {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
    }
    .blue_option {
      width: 100%;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #52acff;
    }
    .input_blue {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
    }
    .offer_button_box {
      width: 60%;
      height: 70px;
      border-radius: 10px;
      background: #ff9921;
      margin-top: 5%;
      margin-left: 20%;
    }
    .input_offer_button {
      color: #2c2c2c;
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 8%;
    }
  }
  @media only screen and (max-width: 510px) {
    .section_style {
      background: #2c2c2c;
      width: 100%;
      height: 1740px;
      background-size: cover;
      margin-top: 10%;
    }
  
    .property_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 3%;
    }
    .selling_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .yellow_option {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #ff9921;
    }
    .input_proparty {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 1%;
    }
    .points_yellow {
      width: 31px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 67px;
      background: #ff9921;
      margin-left: 2%;
      margin-top: 2% !important;
    }
    .input_options {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #2c2c2c;
      background: #d1d1d1;
    }
    .input_options1 {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #000;
      background: #ffffff;
    }
    .input_box {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
    }
  
    .input_name1 {
      color: #000;
      text-align: center;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 3%;
    }
    .input_name {
      color: #2c2c2c;
      text-align: start;
      font-family: "Inter";
      font-size: 23px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 10% !important;
      padding-left: 140%;
      width: 290px;
    }
    .green_option {
      width: 100%;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #9ed600;
    }
    .input_green {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
    }
    .blue_option {
      width: 100%;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #52acff;
    }
    .input_blue {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
    }
    .offer_button_box {
      width: 80%;
      height: 70px;
      border-radius: 10px;
      background: #ff9921;
      margin-top: 8%;
      margin-left: 10%;
    }
    .input_offer_button {
      color: #2c2c2c;
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 7%;
    }
  }

  @media only screen and (max-width: 420px) {
    .section_style {
      background: #2c2c2c;
      width: 100%;
      height: 1740px;
      background-size: cover;
      margin-top: 25%;
    }
  
    .property_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 3%;
    }
    .selling_heading {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .yellow_option {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #ff9921;
    }
    .input_proparty {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 3%;
    }
    .points_yellow {
      width: 29px;
      height: 28px;
      flex-shrink: 0;
      border-radius: 67px;
      background: #ff9921;
      margin-left: 2%;
      margin-top: 4% !important;
    }
    .input_options {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #2c2c2c;
      background: #d1d1d1;
    }
    .input_options1 {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      border: 1px solid #000;
      background: #ffffff;
    }
    .input_box {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
    }
  
    .input_name1 {
      color: #000;
      text-align: center;
      font-family: "Inter";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 6%;
    }
    .input_name {
      color: #2c2c2c;
      text-align: start;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 18% !important;
      padding-left: 140%;
      width: 290px;
    }
    .green_option {
      width: 100%;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #9ed600;
    }
    .input_green {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 4%;
    }
    .blue_option {
      width: 100%;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #52acff;
    }
    .input_blue {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 5%;
    }
    .offer_button_box {
      width: 80%;
      height: 60px;
      border-radius: 10px;
      background: #ff9921;
      margin-top: 8%;
      margin-left: 10%;
    }
    .input_offer_button {
      color: #2c2c2c;
      text-align: center;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 8%;
    }
  }
  
