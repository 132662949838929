.main_div {
    display: flex;
    align-items: start;
    width: 100%;
    height: 94px;
  }
  .background_space {
    width: 100%;
  
    height: 94px;
    flex-shrink: 0;
    background: #FF9921;
  }
  .clients_feedback {
    color: #2c2c2c;
    text-align: center;
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0%;
  }
  
  @media only screen and (max-width: 800px){
      .main_div {
          display: flex;
          align-items: start;
          width: 100%;
        }
        .background_space {
          width: 100%;
        
          height: 94px;
          flex-shrink: 0;
          background: #FF9921;
        }
        .clients_feedback {
          color: #2c2c2c;
          text-align: center;
          font-family: "Inter";
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-top: 1%;
        }
  }
  
  @media only screen and (max-width: 530px){
      .main_div {
          display: flex;
          align-items: start;
          width: 100%;
        }
        .background_space {
          width: 100%;
        
          height: 80px;
          flex-shrink: 0;
          background: #FF9921;
        }
        .clients_feedback {
          color: #2c2c2c;
          text-align: center;
          font-family: "Inter";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-top: 2%;
        }
  }