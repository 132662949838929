.location_photo {
    background-color:#2C2C2C;
    width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    height: 100% !important;
    flex-shrink: 0;
    padding-bottom: 3%;
  }
  .buy_houses{
      color: #FFF;
  text-align: center;
  font-family: "Inter";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 2%;
  }
  .location_points_img{
      width: 24px;
  height: 34px;
  fill: #F6B200;
  margin-left: 70%;
  margin-top: 0% !important;
  }
  .inputs_locations{
      color: #FFF;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .location_points2_img{
      width: 24px;
      height: 34px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top: 32% !important;
  }
  .inputs_locations2{
      color: #FFF;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 9%;
  }

  @media only screen and (max-width: 1400px) {
    .location_photo {
      background-color: #2C2C2C;
        width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
        height: 612px;
        flex-shrink: 0;
      }
      .buy_houses{
          color: #FFF;
      text-align: center;
      font-family: "Inter";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
      }
      .location_points_img{
          width: 24px;
      height: 35px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top: 0% !important;
      }
      .inputs_locations{
          color: #FFF;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      }
      .location_points2_img{
          width: 24px;
          height: 35px;
          fill: #F6B200;
          margin-left: 70%;
          margin-top: 32% !important;
      }
      .inputs_locations2{
          color: #FFF;
          font-family: "Inter";
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 9%;
      }
}

@media only screen and (max-width: 1200px) {
    .location_photo {
        background-color: #2C2C2C;
        width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
        height: 612px;
        flex-shrink: 0;
      }
      .buy_houses{
          color: #FFF;
      text-align: center;
      font-family: "Inter";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
      }
      .location_points_img{
          width: 24px;
      height: 35px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top: 0% !important;
      }
      .inputs_locations{
          color: #FFF;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      }
      .location_points2_img{
          width: 24px;
          height: 35px;
          fill: #F6B200;
          margin-left: 70%;
          margin-top: 34% !important;
      }
      .inputs_locations2{
          color: #FFF;
          font-family: "Inter";
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 9%;
      }
}

@media only screen and (max-width: 1000px) {
    .location_photo {
        background-color: #2C2C2C;
        width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
        height: 612px;
        flex-shrink: 0;
      }
      .buy_houses{
          color: #FFF;
      text-align: center;
      font-family: "Inter";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
      }
      .location_points_img{
          width: 20px;
      height: 28px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top:0% !important;
      }
      .inputs_locations{
          color: #FFF;
      font-family: "Inter";
      font-size: 23px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      }
      .location_points2_img{
          width: 20px;
          height: 28px;
          fill: #F6B200;
          margin-left: 70%;
          margin-top: 40% !important;
      }
      .inputs_locations2{
          color: #FFF;
          font-family: "Inter";
          font-size: 23px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 9%;
      }
}

@media only screen and (max-width: 767px) {
    .location_photo {
        background-color: #2C2C2C;
        width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
        height: 612px;
        flex-shrink: 0;
      }
     


      .buy_houses{
          color: #FFF;
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 2%;
      }
      .location_points_img{
          width: 17px;
      height: 27px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top:0% !important;
      }
      .inputs_locations{
          color: #FFF;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 5%;
      }
      .location_points2_img{
          width: 17px;
          height: 27px;
          fill: #F6B200;
          margin-left: 70%;
          margin-top: 34% !important;
      }
      .inputs_locations2{
          color: #FFF;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 9%;
      }
}

@media only screen and (max-width: 580px) {
    .location_photo {
        background-color: #2C2C2C;
        width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
        height: 612px;
        flex-shrink: 0;
      }
     


      .buy_houses{
          color: #FFF;
      text-align: center;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 10%;
      }
      .location_points_img{
          width: 14px;
      height: 24px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top:0% !important;
      }
      .inputs_locations{
          color: #FFF;
      font-family: "Inter";
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 10%;
      }
      .location_points2_img{
          width: 14px;
          height: 23px;
          fill: #F6B200;
          margin-left: 70%;
          margin-top: 45% !important;
      }
      .inputs_locations2{
          color: #FFF;
          font-family: "Inter";
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 9%;
      }
}



@media only screen and (max-width: 484px) {
    .location_photo {
        background-color: #2C2C2C;
        width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
        height: 612px;
        flex-shrink: 0;
      }
     


      .buy_houses{
          color: #FFF;
      text-align: center;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 10%;
      }
      .location_points_img{
          width: 12px;
      height: 22px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top: 0% !important;
      }
      .inputs_locations{
          color: #FFF;
      font-family: "Inter";
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 10%;
      }
      .location_points2_img{
          width: 12px;
          height: 22px;
          fill: #F6B200;
          margin-left: 70%;
          margin-top: 40% !important;
      }
      .inputs_locations2{
          color: #FFF;
          font-family: "Inter";
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 9%;
      }
}

@media only screen and (max-width: 425px) {
    .location_photo {
        background-color: #2C2C2C;
        width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
        height: 612px;
        flex-shrink: 0;
      }
     


      .buy_houses{
          color: #FFF;
      text-align: center;
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 10%;
      }
      .location_points_img{
          width: 10px;
      height: 20px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top: 0% !important;
      }
      .inputs_locations{
          color: #FFF;
      font-family: "Inter";
      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 18%;
      }
      .location_points2_img{
          width: 10px;
          height: 20px;
          fill: #F6B200;
          margin-left: 70%;
          margin-top: 60% !important;
      }
      .inputs_locations2{
          color: #FFF;
          font-family: "Inter";
          font-size: 9px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 10% !important;
      }
}

@media only screen and (max-width: 368px) {
    .location_photo {
        background-color: #2C2C2C;
        width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
        height: 612px;
        flex-shrink: 0;
      }
     


      .buy_houses{
          color: #FFF;
      text-align: center;
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 10%;
      }
      .location_points_img{
          width: 10px;
      height: 20px;
      fill: #F6B200;
      margin-left: 70%;
      margin-top: 0% !important;
      }
      .inputs_locations{
          color: #FFF;
      font-family: "Inter";
      font-size: 7px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 20%;
      }
      .location_points2_img{
          width: 10px;
          height: 18px;
          fill: #F6B200;
          margin-left: 70%;
          margin-top: 60% !important;
      }
      .inputs_locations2{
          color: #FFF;
          font-family: "Inter";
          font-size: 7px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 9%;
      }
}

  