/* .navbar-toggler {
  color: #fff;
  background-color: #fff;
} */

.logo_style {
  margin-top: 5px;
  width: 14%;
  height: 70px;
}
.main_div_navbar {
  background-color: #ffffff;
}
.navbar_sell {
  color: #f6b200;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 700;
  word-wrap: break-word;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 12px;
  text-decoration: none;
}
.navbar_para {
  color: #0e0c0c;
  font-size: 16px ;
  font-family: "Inter";
  font-weight: 700;
  word-wrap: break-word;
  cursor: pointer;
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-top: 12px ;
  text-decoration: none;
 
  
}
.navbar_para:hover {
  color: #f6b200;
}


@media only screen and (max-width: 2561px) {
  .logo_style {
    margin-top: 10px;
    width: 7%;
    height: 70px;
  }

  .navbar_para {
    color: #0c0b0b;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px !important;
    padding-left:10px !important;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 2000px) {
  .logo_style {
    width: 8%;
    height: 70px;
  }

  .navbar_para {
    color: #0a0a0a;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px !important;
    padding-left: 10px !important;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 1400px) {
  .logo_style {
    width: 12%;
    height: 70px;
  }

  .navbar_para {
    color: #0e0d0d;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px !important;
    padding-left: 10px !important;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 1100px) {
  .logo_style {
    width: 15%;
    height: 70px;
  }

  .navbar_para {
    color: #0e0d0d;
    font-size: 16px !important;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px !important;
    padding-left: 10px !important;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 850px) {
  .logo_style {
    width: 25%;
    height: 90px;
  }

  .navbar_sell {
    color: #f6b200;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
   padding-right: 10px !important;
  padding-left: 10px !important;
    padding-top: 12px;
  }
  .navbar_para {
    color: #0e0d0d;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 660px) {
  .logo_style {
    width: 30%;
    height: 84px;
    margin-top: 2%;
  }

  .navbar_sell {
    color: #f6b200;
    font-size: 10px;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para {
    color: #0e0d0d;
    font-size: 12px !important;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 600px) {
  .logo_style {
    width: 30%;
    height: 84px;
    margin-top: 2%;
  }

  .navbar_para {
    color: #0c0c0c;
    font-size: 10px !important;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 470px) {
  .logo_style {
    width: 30%;
    height: 84px;
    margin-top: 2%;
  }

  .navbar_sell {
    color: #f6b200;
    font-size: 9px !important;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para {
    color: #0c0c0c;
    font-size: 10px;
    font-family: "Inter";
    font-weight: 900;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 445px) {
  .logo_style {
    width: 35%;
    height: 80px;
    margin-top: 2%;
  }

  .navbar_sell {
    color: #f6b200;
    font-size: 9px !important;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para {
    color: #0c0c0c;
    font-size: 9px;
    font-family: "Inter";
    font-weight: 1000;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 376px) {
  .logo_style {
    width: 40%;
    height: 80px;
    margin-top: 2%;
  }

 
  .navbar_para {
    color: #0a0a0a;
    font-size: 8px !important;
    font-family: "Inter";
    font-weight: 800;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para:hover {
    color: #f6b200;
  }
}

@media only screen and (max-width: 321px) {
  .logo_style {
    width: 40%;
    height: 84px;
    margin-top: 2%;
  }

  .navbar_sell {
    color: #f6b200;
    font-size: 5px;
    font-family: "Inter";
    font-weight: 700;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
  }
  .navbar_para {
    color: #0a0a0a;
    font-size: 8px !important;
    font-family: "Inter";
    font-weight: 800;
    word-wrap: break-word;
    cursor: pointer;
    padding-right: 10px;
    padding-left:10px;
    padding-top: 12px;
    direction: column;
    height: 35px;
    
  }
  .navbar_para:hover {
    color: #f6b200;
  }
  
}
