.background_style3 {
    display: flex;
    direction: row;
    align-items: center;
   
    width: 100%;
    height: 530px;
    flex-shrink: 0;
    background: #FF9921;
  }
  .trusted_heading {
    width: 100%;
    color: #fff;
    text-align: center;
    text-shadow: 0px 10px 3px #FF7A00;
    font-family: 'Kavoon';
    font-size: 74px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .cash_offer{
      width: 100%;
      color: #FFF;
  
  text-align: center;
  font-family: "Inter";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 22%;
    padding-right: 22%;
    margin-top: 2%;
  }
  .doller_img{
    width: 472.123px;
  height: 446.768px;
  flex-shrink: 0;
  }

  @media only screen and (max-width: 2500px){
    .background_style3 {
      display: flex;
      direction: row;
      align-items: center;
     
      width: 100%;
      height: 530px;
      flex-shrink: 0;
      background: #FF9921;
    }
    .trusted_heading {
      width: 100%;
      color: #fff;
      text-align: center;
      text-shadow: 0px 10px 3px #FF7A00;
      font-family: 'Kavoon';
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 19%;
      padding-right:5%;
    }
    .cash_offer{
        width: 100%;
        color: #FFF;
    
    text-align: center;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 32%;
      padding-right: 15%;
      margin-top: 2%;
    }
    .doller_img{
      width: 472.123px;
    height: 446.768px;
    flex-shrink: 0;
    }
  }
  
  @media only screen and (max-width: 1900px){
    .background_style3 {
      display: flex;
      direction: row;
      align-items: center;
     
      width: 100%;
      height: 530px;
      flex-shrink: 0;
      background: #FF9921
    }
    .trusted_heading {
      width: 100%;
      color: #fff;
      text-align: center;
      text-shadow: 0px 10px 3px #FF7A00;
      font-family: 'Kavoon';
      font-size: 70px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 0%;
      padding-right:0%;
    }
    .cash_offer{
        width: 100%;
        color: #FFF;
    
    text-align: center;
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 14%;
      padding-right: 14%;
      margin-top: 2%;
    }
    .doller_img{
      width: 472.123px;
    height: 446.768px;
    flex-shrink: 0;
    }
  }
  
  @media only screen and (max-width: 1400px){
    .background_style3 {
      display: flex;
      direction: row;
      align-items: center;
     
      width: 100%;
      height: 530px;
      flex-shrink: 0;
      background: #FF9921
    }
    .trusted_heading {
      width: 100%;
      color: #fff;
      text-align: center;
      text-shadow: 0px 10px 3px #FF7A00;
      font-family: 'Kavoon';
      font-size: 55px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 0%;
      padding-right:0%;
    }
    .cash_offer{
        width: 100%;
        color: #FFF;
    
    text-align: center;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 14%;
      padding-right: 14%;
      margin-top: 2%;
    }
    .doller_img{
      width: 472.123px;
    height: 446.768px;
    flex-shrink: 0;
    }
  }
  
  @media only screen and (max-width: 1140px){
    .background_style3 {
      display: flex;
      direction: row;
      align-items: center;
     
      width: 100%;
      height: 530px;
      flex-shrink: 0;
      background: #FF9921
    }
    .trusted_heading {
      width: 100%;
      color: #fff;
      text-align: center;
      text-shadow: 0px 10px 3px #FF7A00;
      font-family: 'Kavoon';
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 0%;
      padding-right:0%;
    }
    .cash_offer{
        width: 100%;
        color: #FFF;
    
    text-align: center;
    font-family: "Inter";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 14%;
      padding-right: 14%;
      margin-top: 2%;
    }
    .doller_img{
      width: 300px;
    height: 300px;
    flex-shrink: 0;
    }
  }
  
  @media only screen and (max-width: 880px){
    .background_style3 {
      display: flex;
      direction: row;
      align-items: center;
     
      width: 100%;
      height: 530px;
      flex-shrink: 0;
      background: #FF9921
    }
    .trusted_heading {
      width: 100%;
      color: #fff;
      text-align: center;
      text-shadow: 0px 10px 3px #FF7A00;
      font-family: 'Kavoon';
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 0%;
      padding-right:0%;
    }
    .cash_offer{
        width: 100%;
        color: #FFF;
    
    text-align: center;
    font-family: "Inter";
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 14%;
      padding-right: 14%;
      margin-top: 4%;
    }
    .doller_img{
      width: 250px;
    height: 250px;
    flex-shrink: 0;
    }
  }
  
  @media only screen and (max-width: 660px){
    .background_style3 {
      display: flex;
      direction: row;
      align-items: center;
     
      width: 100%;
      height: 400px;
      flex-shrink: 0;
      background: #FF9921
    }
    .trusted_heading {
      width: 100%;
      color: #fff;
      text-align: center;
      text-shadow: 0px 10px 3px #FF7A00;
      font-family: 'Kavoon';
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 0%;
      padding-right:0%;
    }
    .cash_offer{
        width: 100%;
        color: #FFF;
    
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 14%;
      padding-right: 14%;
      margin-top: 4%;
    }
    .doller_img{
      width: 200px;
    height: 200px;
    flex-shrink: 0;
    }
  }
  
  @media only screen and (max-width: 500px){
    .background_style3 {
      display: flex;
      direction: row;
      align-items: center;
     
      width: 100%;
      height: 300px;
      flex-shrink: 0;
      background: #FF9921
    }
    .trusted_heading {
      width: 100%;
      color: #fff;
      text-align: center;
      text-shadow: 0px 10px 3px #FF7A00;
      font-family: 'Kavoon';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 0%;
      padding-right:0%;
    }
    .cash_offer{
        width: 100%;
        color: #FFF;
    
    text-align: center;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 14%;
      padding-right: 14%;
      margin-top: 4%;
    }
    .doller_img{
      width: 150px;
    height: 150px;
    flex-shrink: 0;
    }
  }