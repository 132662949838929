.process_work{
    color: #2C2C2C;
text-align: center;
font-family: "Inter";
font-size: 38px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 4%;
}
.paragraf_work{
    color: #2C2C2C;
text-align: center;
font-family: "Inter";
font-size: 26px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 7%;
padding-right: 5%;

}
.heading_word{
    color: #FF9921;
}
.strong_word{
    color: #2C2C2C;

}
.process{
    justify-content: center;
}

.steps_heading{
    color: #2C2C2C;
text-align: center;
font-family: "Inter";
font-size: 34px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;
}
.steps_green_box{
 width: 50px;
height: 50px;
flex-shrink: 0;
border-radius: 70px;
background: #FF9921;
margin-left: 42%;
margin-bottom: 6%;


}
.numbring_steps{
    color: #2C2C2C;
text-align: center;
font-family: "Inter";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;


}

.get_touch{
    color: #2C2C2C;
text-align: center;
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 0%;
margin-bottom: 4%;
}

.get_offer{
    color: #2C2C2C;
text-align: center;
font-family: "Inter";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 0%;
margin-bottom: 4%;
}

.get_paid{
    color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
}

.notepade_image{
    width: 160px;
height: 160px;
flex-shrink: 0;
margin-left: 25%;
margin-bottom: 4%;
}

.instrections_class{
    color: #2C2C2C;
text-align: center;
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-right: 0%;
}

.arrow_class{
    width: 100%;
    height: 100px;
    flex-shrink: 0;
    stroke-width: 10px;
stroke: #2C2C2C;
padding-top: 70%;
margin-top: 95% !important;


}

@media only screen and (max-width: 1400px) {
    .process_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
    }
    .paragraf_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 7%;
    padding-right: 5%;
    
    }
    .heading_word{
        color: #FF9921;
    }
    .strong_word{
        color: #2C2C2C;
    
    }
    
    .steps_heading{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    }
    .steps_green_box{
     width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 70px;
    background: #FF9921;
    margin-left: 39%;
    margin-bottom: 6%;
    
    
    }
    .numbring_steps{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    
    }
    
    .get_touch{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    }
    
    .get_offer{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    }
    
    .get_paid{
        color: #2C2C2C;
        text-align: center;
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 0%;
        margin-bottom: 4%;
    }
    
    .notepade_image{
        width: 160px;
    height: 160px;
    flex-shrink: 0;
    margin-left: 20%;
    margin-bottom: 4%;
    }
    
    .instrections_class{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0%;
    }
    
    .arrow_class{
        width: 100%;
        height: 100px;
        flex-shrink: 0;
        stroke-width: 10px;
    stroke: #2C2C2C;
    padding-top: 70%;
    margin-top: 95% !important;
    
    
    }
}

@media only screen and (max-width: 1200px) {
    .process_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
    }
    .paragraf_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 7%;
    padding-right: 5%;
    
    }
    .heading_word{
        color: #FF9921;
    }
    .strong_word{
        color: #2C2C2C;
    
    }
    
    .steps_heading{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    }
    .steps_green_box{
     width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 70px;
    background: #FF9921;
    margin-left: 39%;
    margin-bottom: 6%;
    
    
    }
    .numbring_steps{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    
    }
    
    .get_touch{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    }
    
    .get_offer{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    }
    
    .get_paid{
        color: #2C2C2C;
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 0%;
        margin-bottom: 4%;
    }
    
    .notepade_image{
        width: 160px;
    height: 160px;
    flex-shrink: 0;
    margin-left: 14%;
    margin-bottom: 4%;
    }
    
    .instrections_class{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0%;
    }
    
    .arrow_class{
        width: 100%;
        height: 100px;
        flex-shrink: 0;
        stroke-width: 10px;
    stroke: #2C2C2C;
    padding-top: 90%;
    margin-top: 130% !important;
    
    
    }
}

@media only screen and (max-width: 992px) {
    .process_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
    }
    .paragraf_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 0%;
    padding-right: 0%;
    
    }
    .heading_word{
        color: #FF9921;
    }
    .strong_word{
        color: #2C2C2C;
    
    }
    
    .steps_heading{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    }
    .steps_green_box{
     width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 70px;
    background: #FF9921;
    margin-left: 39%;
    margin-bottom: 6%;
    
    
    }
    .numbring_steps{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    
    }
    
    .get_touch{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    }
    
    .get_offer{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    }
    
    .get_paid{
        color: #2C2C2C;
        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 0%;
        margin-bottom: 4%;
    }
    
    .notepade_image{
        width: 100%;
    height: 140px;
    flex-shrink: 0;
    margin-left: 0%;
    margin-bottom: 4%;
    }
    
    .instrections_class{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0%;
    }
    
    .arrow_class{
        width: 110%;
        height: 100px;
        flex-shrink: 0;
        stroke-width: 10px;
    stroke: #2C2C2C;
    padding-top: 140%;
    margin-top: 90% !important;
    
    
    }
}

@media only screen and (max-width: 767px) {
    .process_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
    }
    .paragraf_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 0%;
    padding-right: 0%;
    
    }
    .heading_word{
        color: #FF9921;
    }
    .strong_word{
        color: #2C2C2C;
    
    }
    .process{
        justify-content: center;
    }
    
    .steps_heading{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    }
    .steps_green_box{
     width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 70px;
    background: #FF9921;
    margin-left: 44%;
    margin-bottom: 2%;
    margin-top: 5% !important;
    
    
    }
    .numbring_steps{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    
    }
    
    .get_touch{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    padding-top: 4% ;
    }
    
    .get_offer{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    padding-top: 4% ;
    }
    
    .get_paid{
        color: #2C2C2C;
        text-align: center;
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 0%;
        margin-bottom: 4%;
        padding-top: 4% ;
    }
    
    .notepade_image{
        width: 40%;
    height: 102px;
    flex-shrink: 0;
    margin-left: 29%;
    margin-bottom: 4%;
    }
    
    .instrections_class{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0%;
    padding-top: 4%;
    }
    
    .arrow_class{
        width: 180%;
        height: 45px;
        flex-shrink: 0;
        /* stroke-width: 10px; */
    stroke: #2C2C2C;
    padding-top: 0%;
    margin-top: -90% !important;
    margin-left: -30% !important;
    
    }
}


@media only screen and (max-width: 565px) {
    .process_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4%;
    }
    .paragraf_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 0%;
    padding-right: 0%;
    
    }
    .heading_word{
        color: #FF9921;
    }
    .strong_word{
        color: #2C2C2C;
    
    }
    .process{
        justify-content: center;
    }
    
    .steps_heading{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    }
    .steps_green_box{
     width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 70px;
    background: #FF9921;
    margin-left: 43%;
    margin-bottom: 2%;
    margin-top: 10% !important;
    
    
    }
    .numbring_steps{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    
    }
    
    .get_touch{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    padding-top: 4% ;
    }
    
    .get_offer{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    padding-top: 4% ;
    }
    
    .get_paid{
        color: #2C2C2C;
        text-align: center;
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 0%;
        margin-bottom: 4%;
        padding-top: 4% ;
    }
    
    .notepade_image{
        width: 40%;
        height: 102px;
        flex-shrink: 0;
        margin-left: 30%;
        margin-bottom: 4%;
    }
    
    .instrections_class{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0%;
    padding-top: 4%;
    }
    
    .arrow_class{
        width: 200%;
        height: 45px;
        flex-shrink: 0;
        /* stroke-width: 10px; */
    stroke: #2C2C2C;
    padding-top: 0%;
    margin-top: -20% !important;
    margin-left: -60% !important;
    
    }
}

@media only screen and (max-width: 410px) {
    .process_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 8%;
    }
    .paragraf_work{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 0%;
    padding-right: 0%;
    
    }
    .heading_word{
        color: #FF9921;
    }
    .strong_word{
        color: #2C2C2C;
    
    }
    .process{
        justify-content: center;
    }
    
    .steps_heading{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    }
    .steps_green_box{
     width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 70px;
    background: #FF9921;
    margin-left: 42%;
    margin-bottom: 2%;
    margin-top: 10% !important;
    
    
    }
    .numbring_steps{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    
    }
    
    .get_touch{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    padding-top: 4% ;
    }
    
    .get_offer{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0%;
    margin-bottom: 4%;
    padding-top: 4% ;
    }
    
    .get_paid{
        color: #2C2C2C;
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 0%;
        margin-bottom: 4%;
        padding-top: 4% ;
    }
    
    .notepade_image{
        width: 42%;
    height: 102px;
    flex-shrink: 0;
    margin-left: 29%;
    margin-bottom: 4%;
    }
    
    .instrections_class{
        color: #2C2C2C;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0%;
    padding-top: 4%;
    }
    
    .arrow_class{
        width: 250%;
        height: 40px;
        flex-shrink: 0;
        /* stroke-width: 10px; */
    stroke: #2C2C2C;
    padding-top: 0%;
    margin-top: -90% !important;
    margin-left: -50% !important;
    
    }
}






